var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"page-layout marks_entry_individual",attrs:{"grid-list-md":"","pa-0":"","relative":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":""}},[_c('v-card',{staticClass:"primary--border",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"title"},[_vm._v(" Individual Marks Entry "),_c('v-spacer')],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"title pb-0"},[_c('v-flex',{attrs:{"xs4":"","sm4":""}},[_c('v-select',{staticClass:"pa-0",attrs:{"loading":_vm.examLoading,"disabled":!_vm.exams.length,"items":_vm.exams,"label":"Exam","outlined":"","dense":""},model:{value:(_vm.exam),callback:function ($$v) {_vm.exam=$$v},expression:"exam"}})],1),_c('v-flex',{attrs:{"xs8":"","sm8":""}},[_c('v-student-search-field',{attrs:{"disabled":!_vm.exam},on:{"onSelectStudent":_vm.selectedStudent}})],1)],1)],1),(Object.keys(_vm.student).length)?_c('v-container',[_c('v-student-semi-profile',{attrs:{"student":_vm.student}})],1):_vm._e(),(_vm.form.items.data.length)?_c('div',{staticClass:"data-represent"},[_c('div',[_c('span',{staticClass:"d-info"}),_vm._v(" "),_c('strong',[_vm._v(_vm._s(_vm.form.items.data.length))]),_c('small',[_vm._v(" Total Subjects")])]),_c('div',[_c('span',{staticClass:"d-error"}),_vm._v(" "),_c('strong',[_vm._v(_vm._s(_vm.absentStudentsCount))]),_c('small',[_vm._v(" Absent")])]),_c('div',{staticStyle:{"float":"right","margin-left":"auto","margin-right":"40px","color":"#f48e52"}},[_c('strong',[_c('v-icon',{attrs:{"small":"","color":"#f48e52"}},[_vm._v("check_box")]),_vm._v(" Check to mark Absent. ")],1)])]):_vm._e(),_c('v-data-table',{staticClass:"marks_entry",attrs:{"headers":_vm.headers,"hide-default-footer":"","items":_vm.itemData,"loading":_vm.form.loading},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('tr',{class:''},[_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(index + 1))]),_c('td',{staticClass:"text-xs-left"},[_c('strong',{staticStyle:{"font-size":"16px","display":"block","margin-top":"15px"}},[_vm._v(_vm._s(item.name))]),_c('p',{staticStyle:{"font-size":"12px","color":"#777"}},[_vm._v(" Theory "),_c('strong',{staticStyle:{"font-weight":"normal"}},[_vm._v(_vm._s(item.theory))]),_vm._v(","),_c('span',{staticStyle:{"padding-left":"5px"}}),_vm._v(" Practical "),_c('strong',{staticStyle:{"font-weight":"normal"}},[_vm._v(_vm._s(item.practical))])])]),_c('td',{staticClass:"text-right"},[_c('div',{staticClass:"marks_entry_input_container",staticStyle:{"float":"right"}},[(item.is_verified)?_c('strong',[_vm._v(_vm._s(item.oth))]):_vm._e(),(
                      !item.is_verified &&
                        _vm.$auth.can('marks-input-create&marks-input-update')
                    )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.oth),expression:"item.oth"}],staticClass:"mark_entry_input",attrs:{"type":"text","disabled":item.oth === 'Absent'},domProps:{"value":(item.oth)},on:{"focus":function($event){return $event.target.select()},"keyup":function($event){(_vm.changed = true), _vm.checkTheoryVal($event, item, index)},"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "oth", $event.target.value)}}}):_vm._e(),(
                      !item.is_verified &&
                        _vm.$auth.can('marks-input-create&marks-input-update')
                    )?_c('v-checkbox',{attrs:{"value":"Absent","color":"error"},on:{"change":function($event){(_vm.changed = true),
                        _vm.updateMarksAbsentStatus(index),
                        _vm.disabledSubmit()}},model:{value:(item.oth),callback:function ($$v) {_vm.$set(item, "oth", $$v)},expression:"item.oth"}}):_vm._e()],1)]),_c('td',{staticClass:"text-right"},[_c('div',{staticClass:"marks_entry_input_container",staticStyle:{"float":"right"}},[(item.is_verified)?_c('strong',[_vm._v(_vm._s(item.op))]):_vm._e(),(
                      !item.is_verified &&
                        _vm.$auth.can('marks-input-create&marks-input-update')
                    )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.op),expression:"item.op"}],staticClass:"mark_entry_input",attrs:{"type":"text","disabled":item.practical <= 0 || _vm.itemData[index].op === 'Absent'},domProps:{"value":(item.op)},on:{"focus":function($event){return $event.target.select()},"keyup":function($event){(_vm.changed = true), _vm.checkPracticalVal($event, item, index)},"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "op", $event.target.value)}}}):_vm._e(),(
                      !item.is_verified &&
                        _vm.$auth.can('marks-input-create&marks-input-update')
                    )?_c('v-checkbox',{attrs:{"disabled":item.practical <= 0,"value":"Absent","color":"error"},on:{"change":function($event){(_vm.changed = true),
                        _vm.updateMarksAbsentStatus(index),
                        _vm.disabledSubmit()}},model:{value:(item.op),callback:function ($$v) {_vm.$set(item, "op", $$v)},expression:"item.op"}}):_vm._e()],1)])])]}}])}),_c('br'),_c('v-card-actions',[(
              !_vm.verify_status.is_verify &&
                _vm.form.items.data.length > 0 &&
                _vm.$auth.can('marks-input-create&marks-input-update')
            )?_c('v-btn',{attrs:{"loading":_vm.saving,"large":"","block":"","message":"Are you sure you want to un verify the verified entry.","color":"primary","outlined":false,"disabled":!_vm.dataVerfiy || _vm.saving || !_vm.changed},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.form.is_update ? "Update" : "Save")+" ")]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }